import { css } from 'styled-components';

export const hilite = css`
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    position: absolute;
    background-color: rgba(255, 246, 74, 0.74);
    left: -2%;
    right: -2%;
    bottom: -1px;
    height: 33.3%;
    z-index: -1;
  }
`;

export const metaHeading = css`
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #4a457c;
  font-weight: 600;
  margin: 0;
`;
