/* eslint-disable react/display-name */
import React from 'react';

import { FixedImg } from 'src/embeds/image/image-dato';
import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { GitHubButton } from 'src/components/atoms/GitHubButton';
import { Grid } from 'src/components/molecules/Grid';
import { InlineList } from 'src/components/molecules/InlineList';
import * as sys from '../../system';
import * as css from './drawer-cards-narrow.css';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);
  return (
    <section css={[css.section]}>
      <div css={[css.sectionHeader]}>
        <h2 css={[css.heading]}>{heading}</h2>
        {content.length ? (
          <div
            css={[css.content]}
            dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}
          />
        ) : null}
        <GitHubButton
          href="https://github.com/RasaHQ/rasa"
          data-icon="octicon-star"
          data-show-count="true"
          data-size="large"
          aria-label="Star RasaHQ/rasa on GitHub"
        >
          Star
        </GitHubButton>
      </div>
      <Grid columns="1fr 1fr 1fr" css={[sys.wrapLast, sys.frame, css.grid]} centered>
        {callouts.map(({ heading, content, contentNode, media, ctas }, i) => (
          <Card small centered key={i}>
            {media && (
              <FixedImg
                css={`
                  margin: 0 auto 20px;
                `}
                data={media}
                width={(media.width / media.height) * 60}
              />
            )}
            <h3>{heading}</h3>
            {content.length ? (
              <div
                css={[css.content]}
                dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}
              />
            ) : null}
            {ctas?.length > 0 ? (
              <p>
                <strong>
                  <a href={ctas[0].url}>{ctas[0].text} →</a>
                </strong>
              </p>
            ) : null}
          </Card>
        ))}
      </Grid>
      {ctas.length > 0 && (
        <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
          {ctas.map((cta, i) => {
            const ctaFlags = i > 0 ? { secondary: true } : {};
            return (
              <li key={i}>
                <Button to={cta.url} {...ctaFlags}>
                  {cta.text}
                </Button>
              </li>
            );
          })}
        </InlineList>
      )}
    </section>
  );
}
